.feather-16 {
  width: 16px !important;
  height: 16px !important;
}

.feather-24 {
  width: 24px !important;
  height: 24px !important;
}

.feather-32 {
  width: 32px !important;
  height: 32px !important;
}