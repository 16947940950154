body.admin-name-space {
  input[type="text"], input[type="number"], select {
    border: 1px solid $border-gray;
    border-radius: 0;

    &:disabled {
      color: $header-gray;
      background-color: unset;
    }
  }
}