body.admin-name-space {

  h2:not(.MuiTypography-root) {
    font-family: $fontHero;
    font-weight: 800;
    line-height: 58px;
    font-size: 2.5rem;
  }

  h3:not(.MuiTypography-root) {
    font-family: $fontHero;
    font-weight: 800;
    line-height: 37px;
    font-size: 1.8rem;
  }

  .gray {
    color: $header-gray;
  }
}