@import "utils/colors";
@import "utils/font-sizes";
@import "utils/fonts";
@import "utils/mixins";
@import "utils/normalize";
@import "utils/responsivity";
@import "./ui-components";

// $spacer: 22px !default;

body {
  &.admin-name-space {
    background-color: $athens-grey;
    color: $black-pearl;
    font-family: $fontRegular;
    -webkit-font-smoothing: antialiased;
    font-weight: 300;
    font-size: $fsSmall;
    // letter-spacing: 1.17px;

    @include mobile {
      font-size: $fsSmall;
    }
  }
}

a {
  &:link:not(.MuiLink-root, .btn-primary) {
    color: $brand-black;
  }

  &:visited:not(.MuiLink-root, .btn-primary) {
    color: $brand-black;
  }

  &:hover:not(.MuiLink-root) {
    color: $brand-black !important;
    text-decoration: none !important;
  }
}

.hide {
  display: none !important;
}

.underline {
  border-bottom: 2px solid rgba(0, 0, 0, 0.09);
}

.no-scroll {
  overflow: hidden;
}

.alert {
  border-radius: 0px !important;

  p {
    margin: 0 !important;
  }
}
