// Breakpoints
$smallMobile: 399px;
$mediumMobile: 499px;
$mobile: 720px;
$smallestDT: 919px;
$smallerDT: 1023px;
$smallDT: 1280px;

// Media

@mixin smallMobile {
	@media (max-width: $smallMobile) {
		@content;
	}
}

@mixin mediumMobile {
  @media (max-width: $mediumMobile) {
    @content;
  }
}

@mixin mobile {
	@media (max-width: $mobile) {
		@content;
	}
}

@mixin smallDT {
	@media (max-width: $smallDT) {
		@content;
	}
}

@mixin smallerDT {
	@media (max-width: $smallerDT) {
		@content;
	}
}

@mixin smallestDT {
	@media (max-width: $smallestDT) {
		@content;
	}
}