body.admin-name-space {
  table:not(.MuiTable-root) {
    tr {
      min-height: 63px;
    }

    td, th {
      padding: 16px;
      vertical-align: middle;

      &.right {
        text-align: right;
      }

      @media screen and (max-width: 991px) {
        padding: 8px;
      }
    }

    td:first-of-type, th:first-of-type {
      padding: 16px 0;

      @media screen and (max-width: 991px) {
        padding: 8px;
      }
    }

    th {
      border: none;
    }

    thead {
      th {
        text-transform: uppercase;
        font-weight: 100;
        font-size: 12px;
        color: $header-gray;
      }
    }
  }
}

.table-data {
  th, td {
    padding: 16px !important;

    @media screen and (max-width: 991px) {
      padding: 8px !important;
    }
  }
}

table.qr-table {
  padding: initial;
  min-height: initial;

  td, th, tr {
    padding: 0px !important;
  }

  tr {
    min-height: unset !important;
  }
}