@import "ui-components/content-box.scss";
@import "ui-components/header-container.scss";
@import "ui-components/button.scss";
@import "ui-components/table.scss";
@import "ui-components/form.scss";
@import "ui-components/icons.scss";
@import "ui-components/typography.scss";
@import "ui-components/rspec-docs.scss";

body.admin-name-space {

  .breadcrumb {
    background-color: unset;

    padding: 0 0 0.75rem 0;

    @media screen and (max-width: 768px) {
      padding: 12px 0 0 12px;
    }
  }
}
