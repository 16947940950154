div#rspec-docs {
  line-height: 1.8rem;

  h1, h2, h3, h4 {
    font-family: "Ginto Normal Regular";
    font-weight: initial;
  }

  h1 {
    font-size: 2.8rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.8rem;
  }


  ul {
    margin-left: 12px;
  }

  li {
    margin-left: 22px;

    &.group {
      margin-top: 6px;
    }
  }

  .example {
    color: rgb(101, 101, 101);
  }
}