.header-container {
  display: flex;
  justify-items: baseline;
  flex-wrap: wrap;

  h1, h2, h3 {
    width: 50%;
  }

  .buttons-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    .btn {
      margin: 4px 4px 4px 12px;
    }
  }
}
