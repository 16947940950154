@font-face {
  font-family: "Ginto Normal Regular";
  src: url("../fonts/ginto-normal-regular.woff2") format("woff2");
  font-weight: normal;
}
@font-face {
  font-family: "Ginto Normal Bold";
  src: url("../fonts/ginto-normal-bold.woff2") format("woff2");
  font-weight: bold;
}
@font-face {
  font-family: "Ginto Nord";
  src: url("../fonts/ginto-nord-black.woff2") format("woff2");
}
.bold, .mega-bold {
  font-family: "Ginto Normal Bold";
  font-weight: 500;
}

.mega-bold {
  font-size: 32px;
}

.light {
  color: #a2a6ae;
}

.italic, .empty {
  transform: skewX(-14deg);
}

.empty {
  color: #8C8F94;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

input,
textarea {
  border: 0;
  resize: none;
  outline: none;
  font-family: "Ginto Normal Regular";
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

input[type=submit] {
  cursor: pointer;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Ginto Normal Regular";
  font: unset;
}

div.content-box {
  background-color: white;
}

.header-container {
  display: flex;
  justify-items: baseline;
  flex-wrap: wrap;
}
.header-container h1, .header-container h2, .header-container h3 {
  width: 50%;
}
.header-container .buttons-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.header-container .buttons-container .btn {
  margin: 4px 4px 4px 12px;
}

button.btn, input[type=submit],
a.btn,
a[role=button] {
  padding: 16px 32px;
  font-family: "Ginto Nord";
  border: none;
  border-radius: 0;
  line-height: 0px;
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  letter-spacing: 0.115em;
  text-transform: uppercase;
}
button.btn.btn-primary, input[type=submit].btn-primary,
a.btn.btn-primary,
a[role=button].btn-primary {
  background-color: #14141F;
  color: white;
}
button.btn.btn-primary:hover, input[type=submit].btn-primary:hover,
a.btn.btn-primary:hover,
a[role=button].btn-primary:hover {
  background-color: #14141F;
  color: white !important;
}
button.btn.btn-outlined, input[type=submit].btn-outlined,
a.btn.btn-outlined,
a[role=button].btn-outlined {
  background-color: white;
  color: black;
  border: 2px solid black;
}
button.btn.btn-outlined:hover, input[type=submit].btn-outlined:hover,
a.btn.btn-outlined:hover,
a[role=button].btn-outlined:hover {
  background-color: #14141F;
  color: white !important;
}
button.btn.btn-warning, input[type=submit].btn-warning,
a.btn.btn-warning,
a[role=button].btn-warning {
  background-color: yellow;
  color: black;
}
button.btn.btn-warning:hover, input[type=submit].btn-warning:hover,
a.btn.btn-warning:hover,
a[role=button].btn-warning:hover {
  background-color: yellow;
  color: black !important;
}
button.btn.btn-pill, input[type=submit].btn-pill,
a.btn.btn-pill,
a[role=button].btn-pill {
  font-family: "Ginto Normal Regular";
  border: 1px solid black;
  border-radius: 24px;
}
button.btn.btn-secondary, input[type=submit].btn-secondary,
a.btn.btn-secondary,
a[role=button].btn-secondary {
  color: #14141F;
  background-color: unset;
}
button.btn.btn-secondary.btn-inverse, input[type=submit].btn-secondary.btn-inverse,
a.btn.btn-secondary.btn-inverse,
a[role=button].btn-secondary.btn-inverse {
  color: white;
}
button.btn.btn-secondary:hover, input[type=submit].btn-secondary:hover,
a.btn.btn-secondary:hover,
a[role=button].btn-secondary:hover {
  background-color: unset;
}

body.admin-name-space table:not(.MuiTable-root) tr {
  min-height: 63px;
}
body.admin-name-space table:not(.MuiTable-root) td, body.admin-name-space table:not(.MuiTable-root) th {
  padding: 16px;
  vertical-align: middle;
}
body.admin-name-space table:not(.MuiTable-root) td.right, body.admin-name-space table:not(.MuiTable-root) th.right {
  text-align: right;
}
@media screen and (max-width: 991px) {
  body.admin-name-space table:not(.MuiTable-root) td, body.admin-name-space table:not(.MuiTable-root) th {
    padding: 8px;
  }
}
body.admin-name-space table:not(.MuiTable-root) td:first-of-type, body.admin-name-space table:not(.MuiTable-root) th:first-of-type {
  padding: 16px 0;
}
@media screen and (max-width: 991px) {
  body.admin-name-space table:not(.MuiTable-root) td:first-of-type, body.admin-name-space table:not(.MuiTable-root) th:first-of-type {
    padding: 8px;
  }
}
body.admin-name-space table:not(.MuiTable-root) th {
  border: none;
}
body.admin-name-space table:not(.MuiTable-root) thead th {
  text-transform: uppercase;
  font-weight: 100;
  font-size: 12px;
  color: #848484;
}

.table-data th, .table-data td {
  padding: 16px !important;
}
@media screen and (max-width: 991px) {
  .table-data th, .table-data td {
    padding: 8px !important;
  }
}

table.qr-table {
  padding: initial;
  min-height: initial;
}
table.qr-table td, table.qr-table th, table.qr-table tr {
  padding: 0px !important;
}
table.qr-table tr {
  min-height: unset !important;
}

body.admin-name-space input[type=text], body.admin-name-space input[type=number], body.admin-name-space select {
  border: 1px solid #F0F0F0;
  border-radius: 0;
}
body.admin-name-space input[type=text]:disabled, body.admin-name-space input[type=number]:disabled, body.admin-name-space select:disabled {
  color: #848484;
  background-color: unset;
}

.feather-16 {
  width: 16px !important;
  height: 16px !important;
}

.feather-24 {
  width: 24px !important;
  height: 24px !important;
}

.feather-32 {
  width: 32px !important;
  height: 32px !important;
}

body.admin-name-space h2:not(.MuiTypography-root) {
  font-family: "Ginto Nord";
  font-weight: 800;
  line-height: 58px;
  font-size: 2.5rem;
}
body.admin-name-space h3:not(.MuiTypography-root) {
  font-family: "Ginto Nord";
  font-weight: 800;
  line-height: 37px;
  font-size: 1.8rem;
}
body.admin-name-space .gray {
  color: #848484;
}

div#rspec-docs {
  line-height: 1.8rem;
}
div#rspec-docs h1, div#rspec-docs h2, div#rspec-docs h3, div#rspec-docs h4 {
  font-family: "Ginto Normal Regular";
  font-weight: initial;
}
div#rspec-docs h1 {
  font-size: 2.8rem;
}
div#rspec-docs h2 {
  font-size: 2rem;
}
div#rspec-docs h3 {
  font-size: 1.8rem;
}
div#rspec-docs ul {
  margin-left: 12px;
}
div#rspec-docs li {
  margin-left: 22px;
}
div#rspec-docs li.group {
  margin-top: 6px;
}
div#rspec-docs .example {
  color: #656565;
}

body.admin-name-space .breadcrumb {
  background-color: unset;
  padding: 0 0 0.75rem 0;
}
@media screen and (max-width: 768px) {
  body.admin-name-space .breadcrumb {
    padding: 12px 0 0 12px;
  }
}

body.admin-name-space {
  background-color: #F5F5F5;
  color: #071634;
  font-family: "Ginto Normal Regular";
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  font-size: 14px;
}
@media (max-width: 720px) {
  body.admin-name-space {
    font-size: 14px;
  }
}

a:link:not(.MuiLink-root, .btn-primary) {
  color: #14141F;
}
a:visited:not(.MuiLink-root, .btn-primary) {
  color: #14141F;
}
a:hover:not(.MuiLink-root) {
  color: #14141F !important;
  text-decoration: none !important;
}

.hide {
  display: none !important;
}

.underline {
  border-bottom: 2px solid rgba(0, 0, 0, 0.09);
}

.no-scroll {
  overflow: hidden;
}

.alert {
  border-radius: 0px !important;
}
.alert p {
  margin: 0 !important;
}