button.btn, input[type="submit"],
a.btn,
a[role="button"] {
  padding: 16px 32px;
  font-family: $fontHero;
  border: none;
  border-radius: 0;
  line-height: 0px;
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  letter-spacing: 0.115em;
  text-transform: uppercase;

  &.btn-primary {
    background-color: $brand-black;
    color: white;

    &:hover {
      background-color: $brand-black;
      color: white !important;
    }
  }

  &.btn-outlined {
    background-color: white;
    color: black;
    border: 2px solid black;

    &:hover {
      background-color: $brand-black;
      color: white !important;
    }
  }

  &.btn-warning {
    background-color: yellow;
    color: black;

    &:hover {
      background-color: yellow;
      color: black !important;
    }
  }

  &.btn-pill {
    font-family: "Ginto Normal Regular";
    border: 1px solid black;
    border-radius: 24px;
  }

  &.btn-secondary {
    color: $brand-black;
    background-color: unset;

    &.btn-inverse {
      color: white;
    }

    &:hover {
      background-color: unset;
    }
  }
}