$fontRegular: "Ginto Normal Regular";
$fontItalics: "Ginto Normal Italics";
$fontBold: "Ginto Normal Bold";
$fontHero: "Ginto Nord";

@font-face {
  font-family: $fontRegular;
	src: url("../fonts/ginto-normal-regular.woff2") format("woff2");
	font-weight: normal;
}

@font-face {
  font-family: $fontBold;
	src: url("../fonts/ginto-normal-bold.woff2") format("woff2");
	font-weight: bold;
}

@font-face {
  font-family: $fontHero;
	src: url("../fonts/ginto-nord-black.woff2") format("woff2");
}

.bold {
	font-family: $fontBold;
	font-weight: 500;
}

.mega-bold {
	@extend .bold;
	font-size: $fsMega;
}

.light {
	color: $grey-chateau;
}

.italic {
	transform: skewX(-14deg);
}

.empty {
	@extend .italic;
	color: $oslo-grey;
}