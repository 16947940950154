* {
  box-sizing: border-box;
  margin: 0;
	padding: 0;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
}

input,
textarea {
  border: 0;
  resize: none;
	outline: none;
	font-family: $fontRegular;
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
}

input[type="submit"] {
	cursor: pointer;
}


button {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	font-family: $fontRegular;
	font: unset;
}
